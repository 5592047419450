export enum ContentType {
  HOME_PAGE = 'homePage',
  CAREERS_PAGE = 'careersPage',
  ABOUT_US = 'aboutUsPage',
  RESOURCES_PAGE = 'resourcesPage',
  PROJECT_ESTIMATION = 'projectEstimation',
}

export enum ProjectCategory {
  E_COMMERCE = 'E-commerce',
  FINANCE = 'Finance',
  ENTERTAINMENT = 'Entertainment',
  COMMUNICATION = 'Communication',
  SOCIAL_MEDIA = 'Social Media',
  INDUSTRIAL_IOT = 'Industrial IoT',
  SPORTS = 'Sports',
  RESTAURANT_MANAGEMENT = 'Restaurant Management',
  INSURANCE = 'Insurance',
  HUMAN_RESOURCES = 'Human Resources',
  MAINTENANCE_SYSTEM = 'Maintenance System',
  ONLINE_DATING = 'Online Dating',
}
