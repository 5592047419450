import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { CounterDTO } from '~/pages/case-studies/types';

interface Props {
  data: CounterDTO;
}

const Counter = ({ data: { end, duration, suffix } }: Props) => {
  const [number, setNumber] = useState(0);
  const timeoutTime = (duration * 1000) / end;
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (!inView) return;
    const interval = setInterval(() => {
      setNumber((number) => {
        if (number < end) {
          return number + 1;
        } else {
          clearInterval(interval);
          return number;
        }
      });
    }, timeoutTime);

    return () => clearInterval(interval);
  }, [inView, end, timeoutTime]);

  return (
    <div ref={ref}>
      {number}
      {suffix}
    </div>
  );
};

export default Counter;
