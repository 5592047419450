import { ProjectCategory } from '~/enums';
import routes from '~/routes';

import metadata from './metadata';
import samsaraLarge from './assets/samsara-large.jpg';
import zigzag from './assets/zigzag.jpg';
import robn from './assets/robn.jpg';
import samsara from './assets/samsara.jpg';
import hippo from './assets/hippo.jpg';
import practi from './assets/practi.jpg';
import optiondriver from './assets/optiondriver.jpg';
import upkeep from './assets/upkeep.jpg';
import backgroundVideo from '../home/entrySection/assets/background-video.mp4';
import backgroundVideoPreview from '../home/entrySection/assets/background-video-preview.png';

const caseStudies = {
  metadata,
  entrySection: {
    title: 'Results speak louder than words',
    subtitle:
      'The solutions we’ve implemented and the relationships we’ve built will tell you who we truly are, what we believe in, and how we work-no matter if we were a software house or a part of something bigger. See the projects we are excited to put our name on.',
    videoUrl: 'https://www.youtube-nocookie.com/embed/MJff60VB91Y',
    backgroundVideo,
    backgroundVideoPreview,
  },
  numbersSection: {
    title: 'A team up for the job',
    description:
      'Since 2012 we have co-created many successful products with startups and enterprises. Thanks to our experience, we are recommended as a top development company by leading VCs.',
    numbers: [
      {
        number: {
          end: 200,
          duration: 3,
          suffix: '+',
        },
        title: 'Delivered projects',
      },
      {
        number: {
          end: 11,
          duration: 2,
          suffix: '+',
        },
        title: 'Years on the market',
      },
      {
        number: {
          end: 40,
          duration: 2,
          suffix: '+',
        },
        title: 'Collaborating VCs',
      },
      {
        number: {
          end: 100,
          duration: 3,
          suffix: 'M+',
        },
        title: 'Users of our products',
      },
    ],
  },
  projectsSection: {
    projects: [
      {
        name: 'Custom Samsara Integrations',
        img: samsara,
        imgMobile: samsara,
        imgLarge: samsaraLarge,
        category: ProjectCategory.INDUSTRIAL_IOT,
        projectPageUrl: routes.CASE_STUDIES_SAMSARA,
        summary: '',
        description:
          'As official partners, we implement custom software integrations for crucial clients of Samsara – an industrial IoT leader valued at over 5B USD.',
      },
      {
        name: 'ZigZag',
        img: zigzag,
        category: ProjectCategory.SOCIAL_MEDIA,
        projectPageUrl: routes.CASE_STUDIES_ZIGZAG,
        summary: '',
        description:
          'A unique social media platform that focuses on recording videos with friends and co-owning the followers’ engagement.',
      },
      {
        name: 'Robn',
        img: robn,
        category: ProjectCategory.SPORTS,
        projectPageUrl: routes.CASE_STUDIES_ROBN,
        summary: '',
        description:
          'We designed and developed an official companion app for a one-of-a-kind sports device – smart harness.',
      },
      {
        name: 'Hippo Bloat',
        img: hippo,
        category: ProjectCategory.INSURANCE,
        projectPageUrl: routes.CASE_STUDIES_HIPPO,
        summary: '',
        description:
          'We created a web app for a disruptive insurance company. Bloat provides clients with financial protection tied to a home inspection.',
      },
      {
        name: 'Practi White Label',
        img: practi,
        category: ProjectCategory.RESTAURANT_MANAGEMENT,
        projectPageUrl: routes.CASE_STUDIES_PRACTI,
        summary: '',
        description:
          'An advanced website builder for restaurants, equipped with an integrated food delivery system. Created fully by SwingDev. ',
      },
      {
        name: 'Custom UpKeep Integrations',
        img: upkeep,
        category: ProjectCategory.MAINTENANCE_SYSTEM,
        projectPageUrl: routes.CASE_STUDIES_UPKEEP,
        summary: '',
        description:
          'We are making it possible to integrate an innovative UpKeep CMMS software with other tools used by its clients.',
      },
      {
        name: 'Option Driver',
        img: optiondriver,
        category: ProjectCategory.HUMAN_RESOURCES,
        projectPageUrl: routes.CASE_STUDIES_OPTION_DRIVER,
        summary: '',
        description:
          'We programmed and designed a popular compensation design app, used by hundreds of leading companies and VCs.',
      },
    ],
  },
};

export default caseStudies;
