import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { ProjectDTO } from '~/types';

import styles from './ProjectThumbnail.module.scss';
import Image from '~/components/Image';

export type ProjectThumbnailNameSize = 'small' | 'regular' | 'large';

type Props = {
  data: ProjectDTO;
  nameSize?: ProjectThumbnailNameSize;
  showCategory?: boolean;
  showDescription?: boolean;
  main?: boolean;
  showCTA?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
};

const ProjectThumbnail: React.FC<Props> = ({
  data,
  nameSize = 'regular',
  showCategory = false,
  showDescription = false,
  main = false,
  showCTA = false,
  fullWidth = false,
  onClick,
}) => {
  const nameClasses = classNames(styles.name, {
    [styles.nameLarge]: nameSize === 'large',
    [styles.nameSmall]: nameSize === 'small',
  });

  const rootClasses = classNames(styles.root, {
    [styles.fullWidth]: fullWidth,
  });

  const descriptionClasses = classNames(styles.description, {
    [styles.large]: main,
  });

  const textWrapperClasses = classNames(styles.textWrapper, {
    [styles.extraSpace]: main,
  });

  return (
    <div className={rootClasses}>
      <Link
        className={styles.imgWrapper}
        to={data.projectPageUrl}
        onClick={onClick}
      >
        <Image
          img={data.img}
          alt={data.name}
          imgMobile={data.imgMobile}
          className={styles.img}
        />
      </Link>
      <Link
        className={textWrapperClasses}
        to={data.projectPageUrl}
        onClick={onClick}
      >
        {showCategory && <p className={styles.category}>{data.category}</p>}
        <h2 className={nameClasses}>{data.name}</h2>
        {showDescription && (
          <p className={descriptionClasses}>{data.description}</p>
        )}
        {showCTA && <div className={styles.cta}>Read</div>}
      </Link>
    </div>
  );
};

export default ProjectThumbnail;
