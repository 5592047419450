import React, { useCallback } from 'react';

import Layout from '~/components/Layout';
import caseStudies from '~/data/case-studies';
import Numbers from '~/pages/case-studies/components/Numbers';
import Projects from '~/pages/case-studies/components/Projects';
import { AnalyticsService } from '~/services/Analytics';
import HeroVideo from '~/components/HeroVideo';

const CaseStudies = () => {
  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef();

  const {
    metadata,
    entrySection,
    numbersSection: { title, description, numbers },
    projectsSection: { projects },
  } = caseStudies;

  const handleVideoShow = useCallback(() => {
    AnalyticsService.track({
      category: 'Videos',
      action: 'Play',
      label: 'About Us Video',
    });
  }, []);

  return (
    <Layout
      pageTitle={metadata.pageTitle}
      metaDescription={metadata.metaDescription}
    >
      <HeroVideo onVideoShow={handleVideoShow} data={entrySection} />
      <Numbers
        innerRef={scrollRef}
        numbers={numbers}
        title={title}
        description={description}
      />
      <Projects projects={projects} />
    </Layout>
  );
};

export default CaseStudies;
