import React from 'react';
import { ProjectDTO } from '~/types';
import ProjectThumbnail from '~/components/ProjectThumbnail';

import styles from './Projects.module.scss';

interface ProjectsProps {
  projects: ProjectDTO[];
}

const Projects = ({ projects }: ProjectsProps) => {
  return (
    <section className={styles.root}>
      <div className={styles.row}>
        {projects.map((project, index) =>
          project.imgLarge ? (
            <div className={styles.mainProject} key={index}>
              <ProjectThumbnail
                data={{ ...project, img: project.imgLarge }}
                fullWidth
                nameSize='large'
                showCategory
                showCTA
                showDescription
                main
              />
            </div>
          ) : (
            <div className={styles.project} key={index}>
              <ProjectThumbnail
                data={project}
                fullWidth
                nameSize='large'
                showCategory
                showCTA
                showDescription
              />
            </div>
          ),
        )}
      </div>
    </section>
  );
};

export default Projects;
