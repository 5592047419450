import React from 'react';
import { NumbersSectionDTO } from '~/pages/case-studies/types';
import Counter from '~/components/Counter';

import styles from './Numbers.module.scss';

type Props = NumbersSectionDTO & {
  innerRef: React.RefObject<HTMLDivElement>;
};

const Numbers: React.FC<Props> = ({
  numbers,
  title,
  description,
  innerRef,
}) => {
  return (
    <section className={styles.root} ref={innerRef}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>

      <div className={styles.numbers}>
        {numbers.map(({ number, title }, index) => (
          <div key={index} className={styles.column}>
            <div className={styles.number}>
              <Counter data={number} />
            </div>

            <div className={styles.text}>{title}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Numbers;
